<template>
  <div class="form-wrap">
    <el-form ref="form" :model="form" label-position="right" :rules="rules" label-width="80px"
    v-loading="loading">
      <el-form-item label="角色"  prop="roleId">
        <el-select :disabled="editModle" v-model="form.roleId" @change="changeRole">
          <el-option
            v-for="item in userOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.roleId === '1098884185717739577'" label="终端项目" prop="pid">
        <el-select v-model="form.pid" @change="$forceUpdate();">
          <el-option
            v-for="item in cpArray"
            :key="item.id"
            :label="item.pname"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="form.roleId === '1098884185717739522'" label="添加方式" prop="realName">
        <el-radio-group v-model="form.addType" size="mini">
          <el-radio  label="0">新建账号</el-radio>
          <el-radio  label="1">从已有运营人员账号添加</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="form.addType === '0' || type === 'edit'">
      <el-form-item label="用户名" prop="realName">
        <el-input :disabled="editModle" v-model="form.realName" />
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input v-model="form.mobile" />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="用户账号" prop="username">
        <el-input :disabled="editModle" v-model="form.username" />
      </el-form-item>
      <el-form-item label="用户密码" v-if="type === 'add'" prop="password">
        <el-input show-password v-model="form.password" />
      </el-form-item>
      <el-form-item label="用户密码" v-else>
        <el-input show-password v-model="form.password" />
      </el-form-item>
      <el-form-item label="确定密码" v-if="type === 'add'" prop="repeatPassword">
        <el-input show-password v-model="form.repeatPassword" />
      </el-form-item>
      <el-form-item label="确定密码"  v-else>
        <el-input show-password v-model="form.repeatPassword" />
      </el-form-item>
        <el-form-item prop="status" label="用户状态" size="mini">
          <el-radio-group v-model="form.status">
            <el-radio label="1" value="1" >正常</el-radio>
            <el-radio label="0" value="0" checked>停用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="status" label="是否接受日报" size="mini">
          <el-radio-group v-model="form.acceptReport">
            <el-radio label="1" value="1">接受</el-radio>
            <el-radio label="0" value="0">不接受</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="运营人员">
          <el-select v-model="form.realName">
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button size="medium" @click="toBack">返回</el-button>
        <el-button type="primary" size="medium" @click="saveUsser()">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { selectAllRole, saveUser, selctOneUser, updateUser, listAllRunUsers, bindRunUser, getCAdminUser } from '@/api/settings/userManagement'
import { listCP } from '@/api/cProject'
export default {
  data () {
    var validateRepeat = (rule, value, callback) => {
      /* if (value === '') {
        callback(new Error('请再次输入密码'));
      } else */ if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return ({
      editModle: false,
      type: 'add',
      loading: false,
      userList: [],
      cpArray: [],
      form: {
        id: '',
        customerid: '',
        roleId: '',
        mobile: '',
        realName: '',
        email: '',
        username: '',
        password: '',
        repeatPassword: '',
        userSelect: '',
        status: '1',
        acceptReport: '0',
        addType: '0',
        userId: '',
        pid: ''
      },
      userOptions: [],
      rules: {
        roleId: [{ required: true, message: '请选择用户角色', trigger: 'change' }],
        pid: [{ required: true, message: '请选择终端项目', trigger: 'change' }],
        realName: [{ required: true, message: '请输入用户名称', trigger: 'change' }],
        email: [
          { required: true, type: 'email', message: '请输入正确的邮箱地址', trigger: 'change' }
        ],
        mobile: [
          { required: true, pattern: /^1[3456789]\d{9}$/, message: '请输入正确的电话号码', trigger: 'change' }
        ],
        username: [
          { required: true, message: '用户账号不能为空', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '用户密码不能为空', trigger: 'blur' },
          { min: 6, message: '密码长度不低于6位', trigger: 'blur' }
        ],
        repeatPassword: [
          { required: true, validator: validateRepeat, trigger: 'blur' }
        ]
      }
    })
  },
  mounted () {
    this.allRunUsers()
    this.form.customerid = this.$route.params.cid
    this.type = this.$route.meta.type
    this.selectAllRole()
    if (this.type === 'edit') {
      this.selctOneUser(this.$route.params.id)
      this.editModle = true
      eventHub.$emit('msg', '修改用户')
    } else if (this.type === 'add') {
      eventHub.$emit('msg', '创建用户')
    }
    this.fetchCPArray()
  },
  methods: {
    async fetchCPArray () {
      const res = await listCP()
      this.cpArray = res.data
      if (!this.form.pid && this.cpArray && this.cpArray.length > 0) {
        // 在初始化时使用 Vue.set 或 this.$set
        this.$set(this.form, 'pid', this.cpArray[0].id)
      }
    },
    changeRole () {
      if (this.form.roleId !== '1098884185717739522') {
        this.form.addType = '0'
      }
    },
    async allRunUsers () {
      const res = await listAllRunUsers()
      this.userList = res.data
    },
    toBack () {
      if (this.form.customerid === undefined || this.form.customerid === null || this.form.customerid === 'undefined') {
        this.$router.push('/settings/userManagement')
      } else {
        this.$router.push('/settings/userManagement/editCusUser/' + this.form.customerid)
      }
    },
    async bindRun () {
      if (this.form.customerid === undefined || this.form.customerid === null || this.form.customerid === 'undefined') {
        this.form.customerid = null
      }
      const res = await bindRunUser({ userId: this.form.realName, cId: this.form.customerid })
      if (res.code === 0) {
        this.$message.success('添加成功')
        this.toBack()
      } else {
        this.$message.error('添加失败')
        this.toBack()
      }
    },
    async selectAllRole () {
      try {
        const res = await selectAllRole()
        this.userOptions = res.data
        for (var i = 0; i < this.userOptions.length; i++) {
          if (this.userOptions[i].name === '终端用户') {
            this.userOptions.splice(i, 1)
          }
        }
        console.log(res)
      } catch (err) {
        console.log(err)
      }
    },
    async saveUsser () {
      if (this.type === 'edit') {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              if (this.form.customerid === undefined || this.form.customerid === null || this.form.customerid === 'undefined') {
                this.form.customerid = null
              }
              const res = await updateUser(this.form)
              if (res.code === 0) {
                this.$message.success('修改成功')
                this.toBack()
              } else {
                this.$message.error('修改失败')
                this.toBack()
              }
            } catch (err) {
              this.$message.error('修改失败')
              this.toBack()
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            try {
              if (this.form.roleId === '1098884185717739522' && this.form.addType === '1') {
                this.bindRun()
              } else {
                if (this.form.customerid === undefined || this.form.customerid === null || this.form.customerid === 'undefined') {
                  this.form.customerid = null
                }
                const res = await saveUser(this.form)
                if (res.code === 0) {
                  this.$message.success(res.msg)
                  this.toBack()
                } else if(res.code === 210) {
                  this.$message.error(res.msg)
                } else {
                  this.$message.error(res.msg)
                }
              }
            } catch (err) {
              this.$message.error('添加失败')
            }
          } else {
            return false
          }
        })
      }
    },
    async selctOneUser (id) {
      this.loading = true
      try {
        var res = []
        if (this.$route.params.roleId === '1098884185717739577' || this.$route.params.roleId === 1098884185717739577) {
          res = await getCAdminUser({ id: id })
          res.data.roleId = '1098884185717739577'
        } else {
          res = await selctOneUser({ id: id })
        }
        this.form = res.data
        this.form.status += ''
        this.form.acceptReport += ''
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss">
  .form-wrap {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background: #fff;
    box-shadow: 5px 5px 10px rgba(228, 228, 228, .35);
    padding-top: 20px;
    // margin-left: 20px;

    .title {
      font-size: 16px;
      color: #000000;
      margin: 0 0 20px 20px;
    }

    .el-form {
      // display: flex;
      // flex-wrap: wrap;
      padding: 0 30px 20px;

      .el-form-item {
        width: 50%;

        /* &.required {
          label {
            &::before {
              content: '*';
              color: red;
              padding-right: 2px;
            }
          }
        } */

        .el-input {
          width: 80%;
        }

        textarea {
          width: 80%;
          height: 60px;
          border: 1px solid #DCDFE6;
          color: #606266;
          border-radius: 4px;
        }
      }
    }

    .btn-wrap {
      display: flex;

      .el-button {
        margin-left: 30px;
        margin-top: 30px;
      }
    }
  }
</style>
